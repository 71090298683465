export const educationData = [
    {
        id: 1,
        institution: 'Simon Fraser University',
        course: "Master's Degree",
        startYear: 'Apr 2012',
        endYear: 'Jun 2017'
    },
    // {
    //     id: 2,
    //     institution: 'Noakhali Islamia Kamil Madrasah',
    //     course: 'Higher Secondary',
    //     startYear: '2018',
    //     endYear: '2020'
    // },
    // {
    //     id: 3,
    //     institution: 'Baitus Saif Islamia Madrasah',
    //     course: 'Secondary School',
    //     startYear: '2008',
    //     endYear: '2018'
    // },
]